//
// Utilities
// =========
//


//
// SVG integration
//
svg {
    display: block;
    width: 100%;
    height: auto;
}
.svg-container {
    height: 0;
    position: relative;
    svg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}



//
// Option List
//

.list-options {
    list-style: none;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    li {
        position: relative;
        padding: 0 0 0 8px;
        margin: 0 0 5px;
        &:before {
            content: '| ';
            display: inline;
            color: @brand-primary;

            position: absolute;
            left: 0;
            top: -1px;
        }
    }
}

//
// Burger/Close Button
//

@button-size : 20px;
@transition: .3s; // increase this to see the transformations in slow-motion

.lines-button {
    display: inline-block;
    padding: 4px 0;
    transition: .3s;
    cursor: pointer;
    user-select: none;
    border-radius: @button-size/7;
    border: 0;
    background: none;
    outline: none;
    &:hover {
        opacity: 1;
    }
}
    .line {
        display: inline-block;
        width: @button-size;
        height: @button-size/7;
        background: @black;
        border-radius: @button-size/14;
        transition: @transition;
    }
        .lines {
            .line;
            position: relative;
            &:before, &:after {
            .line;
                position: absolute;
                left:0;
                content: '';
                transform-origin: @button-size/14 center;
            }
            &:before { top: @button-size/3.5; }
            &:after { top: -@button-size/3.5; }
        }


.lines-button.x.lines-button-close .lines {
    background: transparent;
    &:before, &:after{
      transform-origin: 50% 50%;
      top:0;
      width: @button-size;
    }
    &:before{
      transform: rotate3d(0,0,1,45deg);
    }
    &:after{
      transform: rotate3d(0,0,1,-45deg);
    }
}


// Font Arrow
.font-arrow {}

// Bootstrap: Accordion
.panel-group .panel {
    border-radius: 0;
    border: 0;
    box-shadow: none;
    margin-bottom: 15px;
}
.panel-group .panel-heading + .panel-collapse > .panel-body {
    border: 0;
}
.panel-title a { display: block; }
.panel-default  > .panel-heading {
    background: @black;
    color: @white;
    position: relative;
    padding-right: 30px;
    border-radius: 0;
    .panel-title {
        font-weight: 300;
        text-transform: uppercase;
        a {
            text-decoration: none;
        }
    }
    .font-arrow {
        position: absolute;
        right: 0;
        top: 50%;
        bottom: 0;
        width: 36px;
        height: 36px;
        margin-top: -18px;
        line-height: 30px;
        text-align: center;

        .transition(all 300ms ease);

    }
    &.active {
        background: @brand-primary;
        color: @black;
        .font-arrow {
            .rotate(90deg);
        }
    }
}

// Responsive-Videos

.responsive-video {
    position: relative;
    padding-bottom: 55%;
    padding-top: 15px;
    height: 0;
    overflow: hidden;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


// Badgees

.badge-circle {
    width: 40px;
    height: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    display: block;
    color: @white;
    background: @brand-primary;
    border-radius: 50%;
    @media (min-width: @screen-xs-min) {
        width: 60px;
        height: 60px;
        font-size: 12px;
        line-height: 13px;
    }
    span {
        display: table-cell;
        height: 40px;
        vertical-align: middle;
        text-decoration: none;
        @media (min-width: @screen-xs-min) {
            height: 60px;
            padding: 5px;
        }
    }
    &.badge-circle {
        width: 60px;
        height: 60px;
        font-size: 11px;
        line-height: 12px;
        @media (min-width: @screen-xs-min) {
            width: 80px;
            height: 80px;
            font-size: 12px;
            line-height: 13px;
        }
        span {
            height: 60px;
            @media (min-width: @screen-xs-min) {
                height: 80px;
            }
        }
    }
}
