h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 15px;
}
p + h1, p + h2, p + h3, p + h4, p + h5, p + h6 {
    margin-top: 35px;
}
h1 { font-size: 2.6em; font-weight: 300; line-height: 1.25em; }
h2 { font-size: 1.8em; font-weight: 300; }
h3 { font-size: 1.6em; font-weight: 300; }
h4 { font-size: 1.4em; font-weight: 300; }
h5 { font-size: 1.3em; font-weight: 300; }
h6 { font-size: 1.1em; }
p {
    font-size: 1em;
    line-height: 1.9em;
    font-weight: 300;
    margin-bottom: 1em;
}