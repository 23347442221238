#sidebar_nav {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;

    opacity: 0;
    pointer-events: none;

    padding-top: 80px;
    padding-left: 0;
    background: #fff;
    box-shadow: 3px 0 6px rgba(0,0,0,.2);

    backface-visibility: hidden;

    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: rotateY(-70deg) translateX(-140px);
            transform: rotateY(-70deg) translateX(-140px);
    @media (max-width: @screen-sm-max) {
        width: 40%;
    }
    @media (max-width: @screen-xs-max) {
        width: 80%;
    }
    @media (min-width: @screen-xs-min) {
        padding-top: 120px;
    }
    @media (min-width: @screen-sm-min) {
        padding-left: 15px;
    }
    @media (min-width: @screen-lg-min) {
        padding-left: 35px;
    }
    &.open {
        opacity: 1;
        pointer-events:  auto;
        -webkit-transform: rotateY(0deg) translateX(0);
                transform: rotateY(0deg) translateX(0);
    }
    .nav {
        margin-bottom: 40px;
        li {
            list-style: none;
            a {
                color: @black;
                text-transform: uppercase;
                display: block;
                padding: 10px 15px;
            }
            &.active > a {
                color: @brand-primary;
            }
        }
        @media (min-width: @screen-sm-min) {
            // 1te Menu-Ebene: ausblenden
            > li > a {
                display: none;
            }
            // 2te Menu-Ebene: Einrückung entfernen
            > li > ul {
                padding-left: 0;
            }
        }
    }

    h3 {
        font-weight: normal;
        font-size: 1em;
    }
    .sidebar-filter {
        padding-left: 15px;
    }
}
// Ausnahme Startseite:
@media (min-width: @screen-sm-min) {
    .front #sidebar_nav .nav > li > a {
        display: block;
    }
}

#sidebar_filter {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999;

    opacity: 0;
    pointer-events: none;

    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    background: #fff;
    box-shadow: -3px 0 6px rgba(0,0,0,.2);

    backface-visibility: hidden;

    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: rotateY(-70deg) translateX(-140px);
            transform: rotateY(-70deg) translateX(-140px);
    @media (max-width: @screen-xs-max) {
        width: 80%;
    }
    &.open {
        opacity: 1;
        pointer-events:  auto;
        -webkit-transform: rotateY(0deg) translateX(0);
                transform: rotateY(0deg) translateX(0);
    }
    h3 {
        .lines-button {
            padding: 0;
            float: left;
        }
        > span {
            display: inline-block;
            padding: 10px;
            font-size: 0.7em;
        }
    }
    .list-unstyled {
        li {
            padding: 5px 0;
            // border-bottom: 1px solid @grey + 100;
        }
    }
}