//
// Breadcrumb
// ==========
//

.breadcrumb {
    background: none;
    padding: 0;
    margin-bottom: 30px;
    @media (min-width: @screen-sm-min) {
        margin-bottom: 50px;
    }
    li {
        font-size: .8em;
    }
    li + li:before {
        content: "|";
    }
}