//
// Content
// =======
//

#content {
    padding: 10px 0 30px;
    @media (min-width: @screen-sm-min) {
        padding: 40px 0 50px;
    }
    .transition(margin 300ms linear);
    &.col-lg-9 {
        @media (min-width: @screen-lg-min) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .responsive-video,
    figure { margin-bottom: 30px; }
    p + figure { margin-top: 30px; }
}