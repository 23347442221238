//
// Frontpage
// =========
//


.frontpage-teaser {
    margin-bottom: 6.4%;
    display: block;
    position: relative;
    h2 {
        position: absolute;
        font-weight: 500;
        font-size: 18px;
        color: @black;
        @media (min-width: @screen-sm-min - 150) {
            font-size: 22px;
        }
        @media (min-width: @screen-sm-min) {
            font-size: 2.6vw;
        }
        span {
            color: @brand-primary;
        }
    }
}


.frontpage-teaser-1 {
    h2 {
        top: 22%;
        left: 53%;
        right: 10px;
    }
}
.frontpage-teaser-2 {
    h2 {
        top: 33%;
        left: 9%;
        right: 50%;
    }
}
.frontpage-teaser-3 {
    h2 {
        left: 10px;
        right: 10px;
        top: 7%;
        text-align: center;
    }
}

#content.col-lg-9 {
    .frontpage-teaser {
        h2 {
            @media (min-width: @screen-sm-min) {
                font-size: 2vw;
            }
        }
    }
}