//
// Theme
// =====
//


//
// Webfonts
//
// @import "Webfont/helvetica.less";

//
// Bootstrap
//

// Core variables and mixins
@import "@{path-bower}/bootstrap/less/variables.less";
@import "@{path-bower}/bootstrap/less/mixins.less";

// Reset and dependencies
@import "@{path-bower}/bootstrap/less/normalize.less";
//@import "@{path-bower}/bootstrap/less/print.less";
@import "@{path-bower}/bootstrap/less/glyphicons.less";

// Core CSS
@import "@{path-bower}/bootstrap/less/scaffolding.less";
@import "@{path-bower}/bootstrap/less/type.less";
//@import "@{path-bower}/bootstrap/less/code.less";
@import "@{path-bower}/bootstrap/less/grid.less";
@import "@{path-bower}/bootstrap/less/tables.less";
@import "@{path-bower}/bootstrap/less/forms.less";
@import "@{path-bower}/bootstrap/less/buttons.less";

// Components
@import "@{path-bower}/bootstrap/less/component-animations.less";
@import "@{path-bower}/bootstrap/less/dropdowns.less";
@import "@{path-bower}/bootstrap/less/button-groups.less";
@import "@{path-bower}/bootstrap/less/input-groups.less";
@import "@{path-bower}/bootstrap/less/navs.less";
@import "@{path-bower}/bootstrap/less/navbar.less";
@import "@{path-bower}/bootstrap/less/breadcrumbs.less";
//@import "@{path-bower}/bootstrap/less/pagination.less";
//@import "@{path-bower}/bootstrap/less/pager.less";
//@import "@{path-bower}/bootstrap/less/labels.less";
//@import "@{path-bower}/bootstrap/less/badges.less";
//@import "@{path-bower}/bootstrap/less/jumbotron.less";
//@import "@{path-bower}/bootstrap/less/thumbnails.less";
//@import "@{path-bower}/bootstrap/less/alerts.less";
//@import "@{path-bower}/bootstrap/less/progress-bars.less";
//@import "@{path-bower}/bootstrap/less/media.less";
@import "@{path-bower}/bootstrap/less/list-group.less";
@import "@{path-bower}/bootstrap/less/panels.less";
@import "@{path-bower}/bootstrap/less/responsive-embed.less";
//@import "@{path-bower}/bootstrap/less/wells.less";
@import "@{path-bower}/bootstrap/less/close.less";

// Components w/ JavaScript
// @import "@{path-bower}/bootstrap/less/modals.less";
// @import "@{path-bower}/bootstrap/less/tooltip.less";
// @import "@{path-bower}/bootstrap/less/popovers.less";
// @import "@{path-bower}/bootstrap/less/carousel.less";

// Utility classes
@import "@{path-bower}/bootstrap/less/utilities.less";
@import "@{path-bower}/bootstrap/less/responsive-utilities.less";


//
// Custom Modules
//

//
// Load global mixins
//

@import "mixins.less";

@import "../css/contrib/swiper.min.css";


@import "modules/scaffolding.less";
@import "modules/typography.less";
@import "modules/utilities.less";
@import "modules/buttons.less";

@import "modules/breadcrumb.less";

@import "modules/header.less";
@import "modules/content.less";
@import "modules/footer.less";
@import "modules/sidebar_nav.less";

@import "modules/frontpage.less";
@import "modules/products.less";
@import "modules/haendlersuche.less";
@import "modules/aktuelles.less";
@import "modules/global-search.less";


//
// Load global Variables
// Note: Needs to be loaded always at the end of the LESS file that gets compiled
//
@import "variables.less";


