.product-categories {
    .row {
        margin: 0;
    }
    .col-xs-6,
    .col-sm-4,
    .col-lg-3 {
        padding: 0;
    }
    .product-category-item {
        position: relative;
        color: @black;
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 20px;
        @media (min-width: @screen-sm-min) {
            margin-bottom: 40px;
        }
        @media (min-width: @screen-lg-min) {
            margin-bottom: 50px;
        }
        .product-category-item-title {
            margin: 0 0 5px;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            @media (min-width: @screen-sm-min) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                text-align: left;
            }
        }
        .product-category-item-image {
            img {
                width: 90%;
                margin: 0 auto;
                @media (min-width: @screen-sm-min) {
                    width: 60%;
                }
            }
        }
    }
}

.product-category-class {
    .row {
        margin: 0;
    }
    .col-xs-6,
    .col-sm-4,
    .col-lg-3 {
        padding: 0;
    }
    .product-category-item {
        position: relative;
        color: @black;
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 30px;
        @media (min-width: @screen-sm-min) {
            margin-bottom: 40px;
        }
        @media (min-width: @screen-lg-min) {
            margin-bottom: 50px;
        }
        .product-category-item-title {
            margin: 0 0 5px;
            font-size: 18px;
            font-weight: 300;
            text-transform: uppercase;
            text-align: center;
            @media (min-width: @screen-sm-min) {
                font-size: 19px;
                left: 62%;
                text-align: left;

                position: absolute;
                top: 15px;
                left: 58%;
                right: 0;
            }
            small {
                display: block;
                padding-top: 3px;
            }
        }
        .product-category-item-image {
            img {
                width: 90%;
                margin: 0 auto;
                @media (min-width: @screen-sm-min) {
                    margin: 0;
                    width: 60%;
                }
            }
        }
        .badge-circle {
            position: absolute;
            left: -5%;
            top: 10%;
        }
    }
}


.product-grid {
    .row {
        margin: 0;
    }
    .col-xs-6,
    .col-sm-4,
    .col-lg-3 {
        padding: 0;
    }
    .product-category-item {
        position: relative;
        color: @black;
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 20px;
        @media (min-width: @screen-sm-min) {
            margin-bottom: 30px;
        }
        @media (min-width: @screen-lg-min) {
            margin-bottom: 45px;
        }
        .product-category-item-image {
            float: left;
            width: 32%;
        }
        .product-category-item-title {
            top: 15px;
            width: 65%;
            right: 0;
            margin: 0;
            float: right;
            h2 {
                font-size: 15px;
                font-weight: 300;
                text-transform: uppercase;
                margin: 0;
            }
            h3 {
                font-size: 22px;
                font-weight: 300;
                text-transform: uppercase;
                margin: 8px 0 8px;
            }
        }
        .list-options {
            font-size: .85em;
        }
    }
}


.product-detail {
    .row {
        border-bottom: 1px solid @brand-primary;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        &:last-child {
            border-bottom: 0;
        }
    }
    .product-info {
        h1 {
            font-weight: normal;
            font-size: 3em;
            margin: 0 0 20px;
        }
        .list-options {
            font-size: .9em;
            margin-bottom: 20px;
        }
        &.col-sm-pull-5 {
            @media (min-width: @screen-sm-min) {
                padding-left: 35px;
            }
            @media (min-width: @screen-lg-min) {
                padding-left: 80px;
            }
        }
    }
    .product-image {
        padding: 0 40px 15px;
        @media (min-width: @screen-sm-min) {
            padding: 0;
        }
        .product-image-swiper {
            width: 100%;
        }
        .swiper-slide {
            -webkit-pointer-events: none;
            pointer-events: none;
            &.swiper-slide-active {
                -webkit-pointer-events: auto;
                pointer-events: auto;
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            background: 0 0;
            font-size: 38px;
            width: 40px;
            text-align: center;
        }
        .badge-circle {
            position: absolute;
            right: 30px;
            bottom: 30px;
            z-index: 9;
        }
    }
    .product-image-thumbs {
        li {
            float: left;
            width: 25%;
            padding-right: 10px;
            margin-bottom: 10px;
            .active {
                box-shadow: 0 0 0px 1px rgba(0,0,0,.1);
                display: block;
            }
        }
    }

    // badges
    .list-badges {
        padding-top: 20px;
        margin-bottom: 0;
        li {
            width: 90px;
            height: 90px;

            display: table-cell;
            vertical-align: middle;
        }
    }

    // product specific file-downloads

    .product-files {
        li {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: @blue;
            }
        }
    }

    .product-lux-swiper {
        .swiper-wrapper {
            margin-bottom: 5px;
            .swiper-slide {
                background: #fff;
                img {
                    margin-bottom: 10px;
                    width: 100%;
                }
                p {
                    margin: 0;
                }
            }
        }
    }
    .product-lux-thumbs {
        margin-left: -8px;
        margin-right: -8px;
        .product-lux-thumb-item {
            float: left;
            width: 25%;
            padding: 8px;
            text-align: center;
            text-decoration: none;
            color: @black;
            @media (min-width: @screen-sm-min) {
                width: 12.5%;
            }
            img { display: block; }
            &.active{
                color: @brand-primary;
                img {
                    box-shadow: 0 0 0 2px @brand-primary;
                }
            }
        }
    }
}

// Image Zoom

.zoomContainer {
    z-index: 9999;
}
.zoomWindow {
    border: 0 !important;
    box-shadow: 0 0 5px rgba(0,0,0,.4);
}