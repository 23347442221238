.aktuelles {
    h1 {
        margin-bottom: 40px;
    }
    .col-sm-6 {
        @media (min-width: @screen-sm-min) {
            padding-right: 50px;
        }
    }
}
.aktuelles-list {
    > .row > div {
        .transition(margin 300ms linear);
    }
    .aktuelles-list-item {
        border-bottom: 1px solid @grey + 100;
        padding-top: 20px;
        padding-bottom: 20px;
        h2 { margin: 0; }
        h2 + p {
            margin: 0 0 20px;
            color: @grey;
            font-size: .8em;
        }

        .col-sm-3 {
            @media (max-width: @screen-xs-max) {
                float: right;
                width: 43%;
                margin: 0 0 10px 10px;
            }
        }
        img {
            display: block;
            margin-right: 30px;
        }
        &:last-child {
            border: 0;
        }
    }
}
