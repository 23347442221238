html,
body {
    height: 100%;
}
body {
    overflow-y: scroll;
}
.page-container  {
    overflow: hidden;
    position: relative;
    min-height: 100%;
    @media (min-width: @screen-sm-min) {
        padding-left: 2em;
        padding-right: 2em;
    }
    @media (min-width: @screen-lg-min) {
        padding-left: 3.5em;
        padding-right: 3.5em;
    }
}