//
// Header
// ======
//

#header {
    padding-top: 20px;
    padding-bottom: 0;
    @media (min-width: @screen-sm-min) {
        padding-bottom: 30px;
    }
    .language-nav {
        font-size: .8em;
        float: right;
        color: @grey;
        a { color: @grey; }
        .active a { color: @black; }
    }
    .menu-trigger-wrapper {
        cursor: pointer;
        position: relative;
        z-index: 9999;
        padding: 8px 0 8px 35px;
        display: inline-block;
        .no-select;
        .lines-button {
            position: absolute;
            left: 0;
            top: 0;
        }
        > span {
            display: inline-block;
            text-transform: uppercase;
            white-space: nowrap;
            display: none;

        }
        &:after {
            content: 'Menu'
        }
    }
    .brand-logo-wrapper {
        float: right;
        width: 20%;
        min-width: 120px;
        clear: both;
        margin-bottom: 20px;
        @media (min-width: @screen-xs-min) {
            min-width: 190px;
        }
        @media (min-width: @screen-lg-min) {
            min-width: 0;
        }
        @media (min-width: @screen-lg-min) {
            margin-bottom: 0;
        }
        .brand-logo {
            display: block;
            width: 100%;
            padding-top: 13px;
            padding-bottom: 10px;
            float: right;
            @media (min-width: @screen-xs-min) {
                padding-top: 10px;
                padding-bottom: 10px;
            }
            @media (min-width: @screen-lg-min) {
                width: 90%;
            }
            .svg-container {
                padding-bottom: 8.6%;
                svg { height: 100%; }
            }
        }
    }
    .search-trigger-wrapper {
        float: right;
        margin-right: 15px;
        text-align: center;
        @media (min-width: @screen-sm-min) {
            clear: both;
            margin-right: 0;
        }
        @media (min-width: @screen-lg-min) {
            width: 10%;
            clear: none;
        }
        .search-trigger {
            @media (max-width: @screen-xs-max) {
                padding: 4px 6px;
                font-size: .8em;
                margin-top: 5px;
            }
        }
    }
    .filter-trigger-wrapper {
        float: right;
        margin-right: 15px;
        .filter-trigger {
            display: inline-block;
            padding: 8px 0;
        }
    }
    #nav {
        display: none;
        @media (min-width: @screen-sm-min) {
            display: block;
        }
        margin-left: -34.5%;
        padding-right: 70px;
        @media (min-width: @screen-lg-min) {
            float: right;
            width: 70%;
            padding-right: 0;
            margin-left: 0;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 2px 0 0 0;
            display: table;
            width: 100%;
            @media (min-width: @screen-lg-min) {
                padding: 0;
            }
            li {
                display: table-cell;
                a {
                    padding: 8px 0px;
                    color: @black;
                    display: block;
                    width: 100%;
                    text-align: left;
                    white-space: nowrap;
                    text-transform: uppercase;
                    @media (min-width: @screen-lg-min) {
                        text-align: center;
                    }
                }
                &.active a {
                    color: @brand-primary;
                }
            }
        }
    }
}