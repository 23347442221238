//
// Footer
// ======
//

#footer {
    border-top: 1px solid @grey;
    padding-top: 30px;
    padding-bottom: 40px;
    .transition(margin 300ms linear);
    .nav-pills {
        margin-bottom: 10px;
        li {
            margin-right: 20px;
            a {
                background: none;
                color: @grey;
                padding: 10px 0;
                text-transform: uppercase;
            }
            &.active a {
                color: @black;
            }
        }
    }
}