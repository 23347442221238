//
// Global Search
//

#global-search {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    padding-top: 10px;

    .transition(transform 300ms ease);
    .translate(0, -60px);
    background: #f3f3f3;

    .container-fluid {
        @media (min-width: 768px) {
            padding-left: 2em;
            padding-right: 2em;
        }
        @media (min-width: 1200px) {
            padding-left: 3.5em;
            padding-right: 3.5em;
        }
    }
    .form-control {
        background: none;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid grey;
        .box-shadow(none);
    }
    .btn {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.open-search #global-search {
    .translate(0, 0);
}
.page-container {
    .translate(0, 0);
    .transition(transform 300ms ease);
}
.open-search .page-container {
    .translate(0, 60px);
}